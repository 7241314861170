function freelancerAutologin() {
    if (checkuserlogin()) console.log("user logged in..."), $("#sidePanelLoader").css("display", "flex");
    else {
        console.log("do user login process..."), userInfo.fn = freelancerInfo.fname, userInfo.sn = freelancerInfo.lname, userInfo.e = freelancerInfo.email, userInfo.com = "Freelancer", userInfo.p = generateRandomPWD() + "@Ws1", userInfo.pn = "+91" + freelancerInfo.mobile, userInfo.dob = formatDate(new Date), userInfo.udd = formatDate(new Date), userInfo.udt = formatDate(new Date), userInfo.flowType = "freelancer", userInfo.job_profile_id = parseInt(freelancerInfo.job_id), userInfo.profile_image_url = freelancerInfo.photo, $("#sidePanelLoader").css("display", "flex");
        var e = generateTrackURL(mainObj.YOU_URL) + "job/tasks?initialUser";
        jQuery.ajax({
            url: generateAPIURL() + "/autologin",
            dataType: "json",
            data: JSON.stringify(userInfo),
            type: "POST",
            headers: generateHeader(),
            success: function(t) {
                console.log("userResponse ==> ", t), t && t.data && t.data.tokens ? (setCookie("accessToken", t.data.tokens.accessToken), setCookie("idToken", t.data.tokens.idToken), setCookie("refreshToken", t.data.tokens.refreshToken), setCookie("sid", t.data.tokens.sid), setCookie("uid", t.data.user_id), setCookie("bid", t.data.brand.id), setCookie("cid", t.data.brand.partner_id), userInfo.brand_referance = mainObj.defaultBrandreference, $("#sidePanelFreelancerLoader").css("display", "none"), $("#sidebar-freelancer").hide(), $("#personalData").hide(), $("#RegisterData").hide(), $("#close-freelancer").show(), $("#complete_registered").show(), redirectUser(e, "freelancer")) : alert("Error while creating user in Cognito")
            },
            error: function(t) {
                console.log("userError ==> ", t), 500 === t.status && "timeout" == t.responseJSON.error_type ? webSocket(t.responseJSON.data).then(t => {
                    console.log("userResponse ==>", t);
                    const a = JSON.parse(t);
                    console.log("userData ==>", a), a && a.tokens ? (setCookie("accessToken", a.tokens.accessToken), setCookie("idToken", a.tokens.idToken), setCookie("refreshToken", a.tokens.refreshToken), setCookie("sid", a.tokens.sid), setCookie("uid", a.user_id), setCookie("bid", a.brand.id), setCookie("cid", a.brand.partner_id), userInfo.brand_referance = mainObj.defaultBrandreference, redirectUser(e, "freelancer")) : (toastr.error("Error while creating user"), $("#sidePanelFreelancerLoader").css("display", "none"))
                }, e => {
                    console.log("createOrder webSocket err ===>", e), toastr.error("Error while creating user"), $("#sidePanelFreelancerLoader").css("display", "none")
                }) : "user_exist" === t.responseJSON.message ? ($("#sidePanelFreelancerLoader").css("display", "none"), $("#userData").css("display", "none"), $("#alreadyRegister").css("display", "block"), setTimeout(function() {
                    RedirectToPlan()
                }, 7e3)) : (toastr.error(t.responseJSON.message), $("#sidePanelFreelancerLoader").css("display", "none"))
            }
        })
    }
}

function addFreelancer(e) {
    $.ajax({
        url: generateAPIURL() + "/addFreelancer",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            slug: mainObj.defaultPartner,
            type: "freelancerLocation",
            subType: "addFreelancer",
            freelancerData: e
        }),
        success: function(e) {
            return dataInfo = "", dataInfo = e.data, THTML = "", $("#registerMsg").empty(), dataInfo && "1" === dataInfo.flag ? THTML = '<img src="img/required-info-icon.svg" alt="">\n              <h2>You are already registered as a freelancer.</h2>\n              <p></p>' : dataInfo && "2" === dataInfo.flag ? THTML = '<img src="img/required-info-icon.svg" alt="">\n              <h2>You are already approved as a freelancer.</h2>\n              <p></p>' : (THTML = '<img src="img/required-info-icon.svg" alt="">\n              <h2>Thank you for the registration.</h2>\n              <p>Please wait while we are redirecting to your profile...</p>', freelancerAutologin()), "1" !== dataInfo.flag && "2" !== dataInfo.flag || ($("#sidePanelFreelancerLoader").css("display", "none"), $("#sidebar-freelancer").hide(), $("#personalData").hide(), $("#RegisterData").hide(), $("#close-freelancer").show(), $("#complete_registered").show()), $("#registerMsg").append(THTML), e
        },
        error: function(e) {
            toastr.error("Something went worng....")
        }
    })
}

function poolDetails() {
    $.ajax({
        url: generateAPIURL() + "/getPartner",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            columns: "c.id, c.name, c.slug, c.wsuite_id, c.provision_complete, cp.service, cp.pool_id, cp.client_id, cp.oauth_url, cp.redirect_url, cp.logout_url, cp.oauth_domain, cp.identity_pool_id",
            where: "c.slug='" + mainObj.defaultPartner + "' AND cp.service='project'"
        }),
        success: function(e) {
            return mainObj.IDENTITY_POOL_ID = e.data[0].identity_pool_id, mainObj.client_id = e.data[0].client_id, e
        },
        error: function(e) {
            console.log("Err ==> ", e)
        }
    })
}

function countryDetails() {
    $.ajax({
        url: generateAPIURL() + "/getCountrylist",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            slug: mainObj.defaultPartner,
            type: "freelancerLocation",
            subType: "country"
        }),
        success: function(e) {
            return $.each(e.data, function(e, t) {
                $("#countryList").append($("<option></option>").attr("value", t.id).text(t.name))
            }), cList = e.data, e
        },
        error: function(e) {
            console.log("packagesError ==> Err ==> ", e)
        }
    })
}

function getState(e) {
    $("#country-require-validation").remove(), $("#stateList").empty(), $.ajax({
        url: generateAPIURL() + "/getStatelist",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            slug: mainObj.defaultPartner,
            type: "freelancerLocation",
            subType: "state",
            countryId: e
        }),
        success: function(e) {
            return $.each(e.data, function(e, t) {
                $("#stateList").append($("<option></option>").attr("value", t.id).text(t.name))
            }), $("#stateLoad").show(), $("#stateLoading").hide(), e
        },
        error: function(e) {
            console.log("packagesError ==> Err ==> ", e)
        }
    })
}

function profileDetails() {
    $.ajax({
        url: generateAPIURL() + "/getProfilelist",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            slug: mainObj.defaultPartner,
            type: "freelancerLocation",
            subType: "profile"
        }),
        success: function(e) {
            return $.each(e.data, function(e, t) {
                $("#profileList").append($("<option></option>").attr("value", t.id).text(t.name))
            }), e
        },
        error: function(e) {
            console.log("profile ==> Err ==> ", e)
        }
    })
}

function freelancerData() {
    if (checkValidFreelancer()) return !1;
    profileDetails(), $("#personalData").hide(), $("#freelancerDataFooter").hide(), $("#RegisterData").show(), $("#freelancerRegisterFooter").show();
    var e = $("#frstName").val(),
        t = $("#laName").val(),
        a = $("#femail").val(),
        o = $("#countryList").val(),
        r = $("#stateList").val(),
        n = $("#phone").val(),
        i = $("#jobdescription").val(),
        l = $("#personalPhoto").attr("src");
    freelancerInfo.photo = l, freelancerInfo.fname = e, freelancerInfo.lname = t, freelancerInfo.email = a, freelancerInfo.country = o, freelancerInfo.state = r, freelancerInfo.mobile = n, freelancerInfo.jobDesc = i, poolDetails()
}

function checkValidFreelancer(e = "all") {
    var t = $("#frstName").val(),
        a = $("#laName").val(),
        o = $("#femail").val(),
        r = $("#countryList").val(),
        n = $("#phone").val(),
        i = $("#jobdescription").val(),
        l = $("#personalPhoto").attr("src"),
        s = !1;
    if ("all" === e || "photo-require-validation" === e)
        if (l) {
            $("#photo-require-validation").hide();
            var d = $("#my-custom-design-upload");
            parseFloat(d[0].files[0].size / 1024).toFixed(2) > 1024 ? ($("#photo-size-validation").show(), s = !0) : $("#photo-size-validation").hide()
        } else $("#photo-require-validation").show(), s = !0;
    return "all" !== e && "fname-require-validation" !== e || (t ? $("#fname-require-validation").hide() : ($("#fname-require-validation").show(), s = !0)), "all" !== e && "lname-require-validation" !== e || (a ? $("#lname-require-validation").hide() : ($("#lname-require-validation").show(), s = !0)), "all" !== e && "femail-require-validation" !== e || (o ? $("#femail-require-validation").hide() : ($("#femail-require-validation").show(), s = !0)), "all" !== e && "femail-valid-validation" !== e || (o && !isEmail($("#femail").val()) ? ($("#femail-valid-validation").show(), s = !0) : $("#femail-valid-validation").hide()), "all" !== e && "country-require-validation" !== e || (r ? $("#country-require-validation").hide() : ($("#country-require-validation").show(), s = !0)), "all" !== e && "phone-require-validation" !== e || (n ? $("#phone-require-validation").hide() : ($("#phone-require-validation").show(), s = !0)), "all" !== e && "phone-valid-validation" !== e || (n && !phonenumber($("#phone").val()) ? ($("#phone-valid-validation").show(), s = !0) : $("#phone-valid-validation").hide()), "all" !== e && "jobdescription-require-validation" !== e || (i ? $("#jobdescription-require-validation").hide() : ($("#jobdescription-require-validation").show(), s = !0)), s
}

function isEmail(e) {
    return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(e)
}

function phonenumber(e) {
    return !!/^\d*(?:\.\d{1,2})?$/.test(e) && (e.length >= 4 && e.length <= 20)
}

function freelancerRegister() {
    console.log(skillArr);
    var e = $("#exp").val(),
        t = $("#profileList").val(),
        a = skillArr,
        o = newFileList2.length,
        r = newFileList2,
        n = 0,
        i = $("#my-custom-design-upload"),
        l = i[0].files.length,
        s = i[0].files;
    if (newFileList && (n = newFileList.length), console.log(freelancerInfo), console.log(checkValidRegisterFreelancer()), checkValidRegisterFreelancer()) return !1;
    o > 0 && uploadS3Portfolio(o, r, "selectfile"), n > 0 && uploadS3Portfolio(n, newFileList, ""), l && uploadS3Photo(l, s);
    for (var d = a.length, c = {}, u = 0; u < d; u++) {
        var f = (f = a[u]).split(" ").join("_");
        c[a[u]] = $("#skill-rate_" + f).val()
    }
    freelancerInfo.exp_year = e, freelancerInfo.job_id = t, freelancerInfo.skill_rate = Object.assign({}, c);
    var p = [...fileUrl, ...fDetailsUrl];
    freelancerInfo.portfolio_urls = Object.assign({}, p), console.log(freelancerInfo), $("#sidePanelFreelancerLoader").css("display", "flex"), setTimeout(function() {
        addFreelancer(freelancerInfo)
    }, 15e3)
}

function checkValidRegisterFreelancer(e = "all") {
    var t = $("#exp").val(),
        a = $("#profileList").val(),
        o = $("#skillList").val(),
        r = newFileList2.length,
        n = !1;
    return newFileList.length > 0 && (r = newFileList.length), "all" !== e && "exp-require-validation" !== e || (t ? $("#exp-require-validation").hide() : ($("#exp-require-validation").show(), n = !0)), "all" !== e && "profile-require-validation" !== e || (a ? $("#profile-require-validation").hide() : ($("#profile-require-validation").show(), n = !0)), "all" !== e && "skill-require-validation" !== e || (0 === o.length ? ($("#skill-require-validation").show(), n = !0) : $("#skill-require-validation").hide()), "all" !== e && "portfolio-require-validation" !== e || (0 === r ? ($("#portfolio-require-validation").show(), n = !0) : $("#portfolio-require-validation").hide()), n
}

function onlyUnique(e, t, a) {
    return a.indexOf(e) === t
}

function getSkill(e) {
    $("#dynamicSkillRate").show(), console.log(e);
    var t = '<div class="skill-rate" id =' + e.split(" ").join("_") + '><div class="input-group"><div class="input-group-prepend">\n\t\t\t\t\t\t\t\t\t\t\t<label class="input-group-text" >' + e + '</label>\n\t\t\t\t\t\t\t\t\t\t  </div>\n\t\t\t\t\t\t\t\t\t\t  <select  id="skill-rate_' + e.split(" ").join("_") + '" class="custome-dropdown-skill">\n\t\t\t\t\t\t\t\t\t\t\t<option selected>0</option>\n\t\t\t\t\t\t\t\t\t\t\t<option value="1">1</option>\n\t\t\t\t\t\t\t\t\t\t\t<option value="2">2</option>\n                      <option value="3">3</option>\n                      <option value="4">4</option>\n                      <option value="5">5</option>\n                      </select></div></div>';
    console.log(t), console.log($("#dynamicSkill")), $("#dynamicSkill").append(t), $(".custome-dropdown-skill").select2({
        tags: !0,
        width: "100%"
    }), skillArr.push(e)
}

function unSelectSkill(e) {
    $("#" + e.split(" ").join("_")).remove();
    var t = skillArr.indexOf(e);
    t > -1 && skillArr.splice(t, 1)
}

function getSkill1(e) {
    $("#skillList :selected").map((e, t) => t.value).get();
    $(".custome-dropdown-skill").select2(), $("#dynamicSkillRate").show(), $("#dynamicSkill").empty();
    var t = $("#skillList").val();
    console.log(t), newskillArr = t.filter(function(e) {
        if (!this[e]) return this[e] = 1, e
    }, {});
    for (var a = "", o = 0; o < newskillArr.length; o++) $(".custome-dropdown-skill").select2(), a = '<div class="col-md-3"><div class="input-group mb-3"><div class="input-group-prepend">\n\t\t\t\t\t\t\t\t\t\t\t<label class="input-group-text" >' + newskillArr[o] + '</label>\n\t\t\t\t\t\t\t\t\t\t  </div>\n\t\t\t\t\t\t\t\t\t\t  <select  id="skill-rate' + o + '" class="custome-dropdown-skill">\n\t\t\t\t\t\t\t\t\t\t\t<option selected>0</option>\n\t\t\t\t\t\t\t\t\t\t\t<option value="1">1</option>\n\t\t\t\t\t\t\t\t\t\t\t<option value="2">2</option>\n                      <option value="3">3</option>\n                      <option value="4">4</option>\n                      <option value="5">5</option>\n                      </select></div></div>', $("#dynamicSkill").append(a);
    $(".custome-dropdown-skill").select2()
}

function uploadS3Portfolio(e, t, a) {
    for (var o = S3Config(), r = 0; r < e; r++) {
        var n = t[r],
            i = n.name,
            l = getFileExtension(i),
            s = l[0] + "_" + (new Date).getTime() + "." + l[1],
            d = mainObj.defaultBrandreference + "/freelancer/" + s;
        a ? fDetailsUrl[r] = "https://s3.amazonaws.com/" + generateBucketName() + "/" + d : fileUrl[r] = "https://s3.amazonaws.com/" + generateBucketName() + "/" + d, o.upload({
            Key: d,
            Body: n,
            ACL: "public-read"
        }, function(e, t) {
            e && console.log(e)
        }).on("httpUploadProgress", function(e) {
            var t = parseInt(100 * e.loaded / e.total);
            $("progress").attr("value", t)
        })
    }
}

function uploadS3Photo(e, t) {
    for (var a = S3Config(), o = 0; o < e; o++) {
        var r = t[o],
            n = r.name,
            i = getFileExtension(n),
            l = freelancerInfo.fname + "_" + (new Date).getTime() + "." + i[1],
            s = mainObj.defaultBrandreference + "/freelancer/" + l;
        freelancerInfo.photo = "https://s3.amazonaws.com/" + generateBucketName() + "/" + s, a.upload({
            Key: s,
            Body: r,
            ACL: "public-read"
        }, function(e, t) {
            e && console.log(e)
        }).on("httpUploadProgress", function(e) {
            var t = parseInt(100 * e.loaded / e.total);
            $("progress").attr("value", t), $("#stateLoading").empty()
        })
    }
}

function removeAllFiles() {
    $("#portfolio-files").empty(), document.getElementById("portfolio-files").value = "", files = "", $("#ulList").empty()
}

function removeSingleFiles(e) {
    $("#dropfile" + e).remove(), 1 === newFileList.length && (e = 0), newFileList.splice(e, 1)
}

function removeSingleFiles2(e) {
    $("#selectfile" + e).remove(), newFileList2.splice(e, 1)
}